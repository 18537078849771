import React from 'react'

const BanniereRando = (props) => (
    <section id="banner" className="style3">
        <div className="inner">
            <header className="major">
                <h1>Solexcursion 2019</h1>
            </header>
          
        </div>
    </section>
)

export default BanniereRando
