import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BanniereRando from '../components/BanniereRando'

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>Solexcursion 2019</title>
            <meta name="description" content="solexcursion 2019, randonnée, solex, vendée" />
        </Helmet>

        <BanniereRando/>

        <div id="main">
            <section id="one">
                <div className="inner">
                    <p>Pour sa 19eme édition, la solexcursion vous emmène sur une balade d'une centaine de kilometres, au départ des Achards et en direction des côtes Vendéennes.</p>
                    <p>Vers Commequiers pour une pause huitres au pied du château, le circuit prendra ensuite la direction de Saint-Hilaire de Riez pour le repas, puis reprendra la direction des achards en passant par Bretignolle</p>
				<ul className="actions">
                                <li><a href="https://forms.gle/X6JZ34cFhgU1QDas8" className="button">Je m'inscris</a></li>
                            </ul>
                </div>
          
            </section>
        </div>

    </Layout>
)

export default Landing
